<template>
  <div class="lookIcons-page">
    <div class="outer">
      <div class="icon-item" v-for="(item, index) in icons" :key="index">
        <div class="icon-box"><i :class="`iconfont ${item}`"></i></div>
        <div>{{item}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    this.icons = [
      'iconjifenguizeguankong',
      'iconiconzhengli-',
      'iconyunfeimoban',
      'iconwenhao',
      'iconrenyuanguanli',
      'iconrenyuanguanli1',
      'iconbianji',
      'iconshipin',
      'icondian',
      'iconcaiwure',
      'icondingdanx',
      'iconshangpinx',
      'iconyunyingx',
      'iconshouye',
      'iconshangpinzhongxin',
      'iconguanli',
      'icondingdan',
      'iconyunyingzhongxin',
      'iconshuju',
      'iconyonghu',
      'iconcaiwu',
      'iconshezhi',
      'iconbangzhuzhongxin',
      'icondianpu',
      'icontianjiashangpin',
      'iconfahuoguanli',
      'icontongguo',
      'iconcuowu',
      'iconfenqidengdaishenhe',
      'icondengdaizhongbeifen',
      'iconbianjidianpu',
      'icondaichuli',
      'iconjinyong',
      'iconxiazai',
      'iconshishixiao',
      'iconyichuli',
      'iconbianji1',
      'iconchakanxiangqing',
      'iconbianji-lv',
      'iconduizhangdan',
      'iconqufahuo',
      'icondianpuxiangqing',
      'iconshanchu',
      'iconqueren'
    ];
    return {};
  }
}
</script>

<style lang="scss" scoped>
.lookIcons-page .outer{
  display: flex; flex-wrap: wrap;
  .iconfont{font-size: 40px;}
}
.icon-item{width: 200px; padding: 10px; margin-bottom: 20px; text-align: center;}
.icon-box{margin-bottom: 10px;}
</style>
