var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "lookIcons-page" }, [
    _c(
      "div",
      { staticClass: "outer" },
      _vm._l(_vm.icons, function (item, index) {
        return _c("div", { key: index, staticClass: "icon-item" }, [
          _c("div", { staticClass: "icon-box" }, [
            _c("i", { class: `iconfont ${item}` }),
          ]),
          _c("div", [_vm._v(_vm._s(item))]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }